import { Col, Container, Row } from 'react-bootstrap';
import Image from 'next/image';
import { getLanguage, t } from 'react-switch-lang';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@awesome.me/kit-3f20e419a7/icons/classic/solid';
import styles from '../styles/LandingPages.module.scss';
import Link, { ExternalLink } from './Link';
import { events } from '../utils/Amplitude';
import RatingStars from './RatingStars';

export default function MobileAppSection({ pageAmpProps }) {
  const lang = getLanguage();
  const mobileAppImgSrc = lang === 'en' ? '/images/stockImages/MobileAppScreenShot.png' : '/images/stockImages/MobileAppScreenShotFr.png';
  return (
    <section className={styles.sectionContainer}>
      <Container fluid="xxl">
        <Row className={styles.mobileAppContentContainer}>
          <Col md={6} xs={12} className={styles.mobileAppScreenShot}>
            <Image
              src={mobileAppImgSrc}
              alt={t('MobileApp_ScreenShot_ImageDescription')}
              height={0}
              width={0}
              className={styles.mobileAppImage}
              sizes="(max-width: 768px) 100vw, 50vw"
            />
          </Col>
          <Col md={6} xs={12} className={styles.mobileAppContent}>
            <div>
              <h2>{t('MobileApp_Section_Title')}</h2>
              <div className={styles.mobileAppText}>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} color="#bb1b20" />
                  {t('MobileApp_Feature_Description1')}
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} color="#bb1b20" />
                  {t('MobileApp_Feature_Description2')}
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} color="#bb1b20" />
                  {t('MobileApp_Feature_Description3')}
                </div>
                <div>
                  <Link
                    href="/mobile"
                    aria-label={t('Footer_MobileAppLearnMore_LinkDescription')}
                    ampEvent={events.USER_CLICKED_MOBILEAPP_LEARN_MORE_LINK}
                    ampEventProps={{ Page: pageAmpProps }}
                  >
                    {t('Footer_MobileApp_CTA')}
                  </Link>
                </div>
              </div>
              <div className={styles.mobileAppDownloadContainer}>
                <div>
                  <ExternalLink
                    href={t('Link_PaySimplyMobileiOSApp')}
                    aria-label={t('PaySimplyMobileApp_AppStore_LinkDescription')}
                    ampEvent={events.USER_CLICKED_LANDINGPAGE_DOWNLOAD_PSM}
                    ampEventProps={{ Store: 'App Store', Page: pageAmpProps }}
                  >
                    <Image
                      src="/images/logos/AppStore.png"
                      height={40}
                      width={140}
                      alt={t('PaySimplyMobileApp_AppStore_ImageDescription')}
                      className="mb-2"
                    />
                  </ExternalLink>
                  <RatingStars rating={4.8} />
                  <p className={styles.ratingText}>{t('MobileApp_AppStore_Rating')}</p>
                </div>
                <div>
                  <ExternalLink
                    href={t('Link_PaySimplyMobileAndroidApp')}
                    aria-label={t('PaySimplyMobileApp_PlayStore_LinkDescription')}
                    ampEvent={events.USER_CLICKED_LANDINGPAGE_DOWNLOAD_PSM}
                    ampEventProps={{ Store: 'Google Play', Page: pageAmpProps }}
                  >
                    <Image
                      src="/images/logos/PlayStore.png"
                      height={40}
                      width={140}
                      alt={t('PaySimplyMobileApp_PlayStore_ImageDescription')}
                      className="mb-2"
                    />
                  </ExternalLink>
                  <RatingStars rating={4.4} />
                  <p className={styles.ratingText}>{t('MobileApp_PlayStore_Rating')}</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
