import { getLanguage, t } from 'react-switch-lang';
import { Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFileInvoiceDollar, faInstitution, faShieldHalved, faUserSlash, faLeafMaple, faCircleDollarToSlot } from '@awesome.me/kit-3f20e419a7/icons/classic/solid';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import MainLayout from '../components/MainLayout';
import styles from '../styles/LandingPages.module.scss';
import HeroSection from '../components/HeroSection';
import ReplaceTextWithElement from '../components/ReplaceTextWithElement';
import Button from '../components/Button';
import { events, logAmpEvent } from '../utils/Amplitude';
import { isProduction } from '../utils/HostingEnv';
import MobileAppSection from '../components/MobileAppSection';
import PaymentSourceSection from '../components/PaymentSourceSection';
import TestimonialSlideShow from '../components/TestimonialSlideShow';
import Link from '../components/Link';
import PaymentOptionsList from '../components/PaymentOptionsList';
import { getPayLinkPrefix } from '../utils/Helpers';

function CTAItem({ link, icon, color, text, ampEventProp, ariaLabel }) {
  return (
    <ListGroup.Item
      action
      className={styles.quickSearchBoxCTA}
      active={false}
      as={Link}
      href={link}
      unsetDefaultClass
      ampEvent={events.USER_CLICKED_MAIN_LANDING_PAGE_CTA}
      ampEventProps={ampEventProp}
      aria-label={ariaLabel}
    >
      <div>
        <FontAwesomeIcon icon={icon} color={color} size="lg" className={styles.ctaIcon} />
        {text}
      </div>
      <FontAwesomeIcon icon={faArrowRight} color="#9A9A9A" size="lg" />
    </ListGroup.Item>
  );
}

function QuickSearchBox() {
  const [query, setQuery] = useState('');
  const router = useRouter();
  const handleSearch = () => {
    const trimmedQuery = query.trim();
    logAmpEvent(events.USER_SEARCHED_BILL_MAIN_LANDING_PAGE, { Query: trimmedQuery });
    router.push(`/partner-search/${encodeURIComponent(trimmedQuery)}`);
  };

  return (
    <ListGroup className={styles.quickSearchBox}>
      <ListGroup.Item>
        <div>
          <h2 className={styles.searchFieldTitle}>
            <label htmlFor="quickSearch">
              {t('PaymentProcess_Search_Prompt')}
            </label>
          </h2>
          <div className={`${styles.searchFieldContainer} my-2`}>
            <Form.Control
              id="quickSearch"
              type="text"
              inputMode="search"
              className={styles.searchField}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
            <Button
              fill="outline"
              className={styles.searchButton}
              ariaLabel={t('MainLanding_Search_ButtonDescription')}
              onClick={handleSearch}
            >
              {t('SearchButton_IconDescription')}
              <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
            </Button>
          </div>
        </div>
      </ListGroup.Item>
      <CTAItem
        link="/cra"
        icon={faLeafMaple}
        color="#BB1B20"
        text={t('MainLanding_HeroSection_CTA1')}
        ampEventProp={{ CTA: t('MainLanding_HeroSection_CTA1', null, 'en') }}
        ariaLabel={t('MainLanding_HeroSection_CTA1_LinkDescription')}
      />
      <CTAItem
        link="/partner-search"
        icon={faFileInvoiceDollar}
        color="#006E04"
        text={t('MainLanding_HeroSection_CTA2')}
        ampEventProp={{ CTA: t('MainLanding_HeroSection_CTA2', null, 'en') }}
        ariaLabel={t('MainLanding_HeroSection_CTA2_LinkDescription')}
      />
      <CTAItem
        link="/lp/albertatra"
        icon={faInstitution}
        color="#FEBA35"
        text={t('MainLanding_HeroSection_CTA3')}
        ampEventProp={{ CTA: t('MainLanding_HeroSection_CTA3', null, 'en') }}
        ariaLabel={t('MainLanding_HeroSection_CTA3_LinkDescription')}
      />
      <CTAItem
        link={isProduction ? `/${getPayLinkPrefix()}/48551` : `/${getPayLinkPrefix()}/43420`}
        icon={faInstitution}
        color="#1B7BC0"
        text={t('MainLanding_HeroSection_CTA4')}
        ampEventProp={{ CTA: t('MainLanding_HeroSection_CTA4', null, 'en') }}
        ariaLabel={t('MainLanding_HeroSection_CTA4_LinkDescription')}
      />
    </ListGroup>
  );
}

export default function Home() {
  const pageAmpEventProp = 'Main Landing Page';
  const lang = getLanguage();
  const interacReplaceKey = lang === 'fr' ? 'Interac' : 'Interac e-Transfer';
  const interacReplaceWith = lang === 'fr' ?
    (
      <i>Interac</i>
    ) :
    (
      <>
        <i>Interac</i>
        {' '}
        <span className={styles.heroTitleNoWrap}>e-Transfer</span>
      </>
    );

  useEffect(() => {
    logAmpEvent(
      events.USER_VIEWED_LANDING_PAGE,
      { Page: pageAmpEventProp }
    );
  }, []);

  const mobileAppImgSrc = lang === 'en' ? '/images/stockImages/MobileAppScreenShot.png' : '/images/stockImages/MobileAppScreenShotFr.png';
  return (
    <MainLayout
      title={t('PageTitle_MainLanding')}
      description={t('PageDescription_MainLanding')}
      ogImage={mobileAppImgSrc}
      ogUrl="https://www.paysimply.ca"
    >
      <HeroSection>
        <Container fluid="xxl">
          <span className={styles.highlightChip}>
            <Image
              src="/images/stockImages/CanadaFlag.png"
              alt=""
              width={40}
              height={40}
              priority
              unoptimized
            />
            {t('MainLanding_OwnershipHighlight')}
          </span>
          <h1 className={`${styles.heroTitle} mt-2`}>
            <ReplaceTextWithElement
              text={t('MainLanding_HeroSection_Title')}
              replaceWith={interacReplaceWith}
              replaceKey={interacReplaceKey}
            />
          </h1>
          <Row>
            <Col md={8} lg={8} xs={12} className={`${styles.heroFeatures}`}>
              <span>
                <FontAwesomeIcon icon={faUserSlash} />
                {t('MainLanding_HeroSection_Feature1')}
              </span>
              <span>
                <FontAwesomeIcon icon={faShieldHalved} />
                {t('MainLanding_HeroSection_Feature2')}
              </span>
              <span>
                <FontAwesomeIcon icon={faCircleDollarToSlot} />
                {t('MainLanding_HeroSection_Feature3')}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={6} xs={12} className="mb-3">
              <QuickSearchBox />
            </Col>
          </Row>
          <div className={styles.heroImageContainer}>
            <Image
              src="/images/stockImages/landingPageHeroImage1.png"
              alt=""
              width={600}
              height={400}
              priority
            />
          </div>
        </Container>
      </HeroSection>
      <PaymentOptionsList />
      <TestimonialSlideShow pageAmpProps={pageAmpEventProp} />
      <MobileAppSection pageAmpProps={pageAmpEventProp} />
      <PaymentSourceSection pageAmpProps={pageAmpEventProp} />
    </MainLayout>
  );
}
